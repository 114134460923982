<template>
  <div id="invitation-bounty-rules">
    <div class="title">
      推荐有奖规则
    </div>
    <div class="rule-question">
      一、如何参加本次活动？
    </div>
    <div class="rule-content">
      （1）已是年费会员的用户才可发起该活动（3天、1个月、
      包车用户暂时无法参与）。 <br />
      <br />
      （2）活动期间内，可在活动页面中复制邀请链接、邀请
      码发给好友或者社交平台上，好友通过您发的邀请链接
      或者下单时输入邀请码下单并上车成功，您将获得相应的 奖励。
    </div>
    <div class="rule-question">
      二、奖励规则？
    </div>
    <div class="rule-content">
      <span style="font-weight: bold;"
        >（1）您邀请的好友下单必须也购买年费会员，且从未在平台下过单的新用户，否则没有奖励。</span
      >
      <br />
      <br />
      （2）双方奖励
    </div>
    <table class="invitation-tb">
      <tr>
        <th>好友下单</th>
        <th>你获得奖励</th>
        <th>好友获得奖励</th>
      </tr>
      <tr>
        <td>普通1年</td>
        <td>1.5元</td>
        <td>1.5元立减</td>
      </tr>
      <tr>
        <td>高级1年</td>
        <td>2.5元</td>
        <td>2.5元立减</td>
      </tr>
    </table>
    <div class="rule-content">
      （3）活动期间内，您每个月最多可邀请 50 个新用户，获得 50 次奖励。
      <br />
      <br />
      <span style="font-weight: bold;"
        >（4）好友下单后，接受邮件邀请，订单状态为「已上车」
        时，相应的奖励就会发放到活动页面中「去提现」，您可
        点击「去提现」按钮申请提现。奖励金额仅支持手动提现，提现金额最晚会在 72
        小时内由公众号youthgood发放现金红包（红包24小时到期，请及时领取，过期不予以重新发放），具体以实际到账时间为准。</span
      >
      <br />
      <br />
      （5）每个月可申请上限1000元，每次提现不得少于 10 元。
    </div>
    <div class="rule-question">
      三、其他说明
    </div>
    <div class="rule-content">
      （1）活动中产生的订单必须为真实订单，在法律允许范
      围内，如发现推荐双方有作弊违规等不当行为，将取消和
      追回所获奖励和冻结账号参与活动资格
      <br />
      <br />
      （2）youthswitch如因市场、运营等实际情况，需要对本
      活动规则做出变更时，有责任及时在活动页更新变更内容
      <br />
      <br />
      （3）本活动由youthswitch组织，如有疑问请联系客服
      <br />
      <br />
    </div>
  </div>
</template>
<script>
export default {
  name: 'invitation-bounty-rules',
  data () {
    return {}
  },
  components: {},
  methods: {},
  created () {}
}
</script>

<style lang="less" scoped>
#invitation-bounty-rules {
  display: flex;
  flex-direction: column;
  width: 7.5rem;
  color: #333333;
  font-size: 0.28rem;
  .title {
    margin-top: 0.38rem;
    margin-left: 0.24rem;
    font-size: 0.48rem;
    font-weight: bold;
  }
  .rule-question {
    margin-left: 0.24rem;
    margin-top: 0.22rem;
    font-weight: bold;
    font-size: 0.32rem;
  }
  .rule-content {
    margin-left: 0.24rem;
    margin-top: 0.24rem;
    margin-right: 0.24rem;
    line-height: 0.44rem;
  }
  .invitation-tb {
    margin-top: 0.3rem;
    font-size: 0.28rem;
    width: 7.02rem;
    color: #333333;
    margin-left: 0.24rem;
    margin-right: 0.24rem;
    &,
    th,
    td {
      border: 0.02rem solid #efefef;
      border-collapse: collapse;
    }
    th {
      height: 0.78rem;
      width: 33%;
      background: #f5f5f5;
    }
    td {
      height: 0.78rem;
      text-align: center;
    }
  }
}
</style>
